import { request } from "../../utils/request";
import { getSignature } from "../../utils/index";
import { useToast, POSITION } from "vue-toastification";
export const common = {
  namespaced: true,
  state: () => ({
    shareList: { 1: false },
    showQuestion: false,
    codeList: {
      40300: "请求太频繁",
      40707: "キャンペーンに重複して参加しないでください",
      40708: "当天已参与活动",
      40010: "UIDに誤りがございます",
      40703: "本イベントは受付を終了しました",
      40702: "活动尚未开始",
      40701:"※このキャンペーンは終了しました※",
    },
    showLogin: true,
    cardShow: false,
    isShowItem: false,
    showList: [{}, {}, {}, {}, {}, {}, {}],
    aDate: new Date().getTime(),
    uid: "",
    isCopy: false,
    obj: {},
    lots: {},
    aData: {},
    date: new Date().getTime(),
    isIntroduce: false,
    isShowLottery: false,
    showAward: false,
    isPhone: navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    ),
    showAwardList: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
  }),
  mutations: {
    openShare(state, showState) {
      if (state.uid && localStorage.getItem(`${state.uid}#`)) {
        let a = JSON.parse(localStorage.getItem(`${state.uid}#`));
        if (a) {
          state.shareList = a;
        }
      }
      console.log(88123, state.uid, state.shareList);
      state.shareList[showState] = true;
      console.log(88123, state.uid, state.shareList);
      if (state.uid && state.uid.length == 19) {
        localStorage.setItem(`${state.uid}#`, JSON.stringify(state.shareList));
      }
      if (!state.isPhone) {
        window.open(
          "https://twitter.com/intent/tweet?text=%E3%80%8E%23ARKA%E2%80%90%E8%92%BC%E7%A9%B9%E3%81%AE%E9%96%80%E3%80%8F%E6%98%9F%E9%9C%8A%20%23%E7%BE%85%E5%88%B9%20%E5%88%9D%E7%99%BB%E5%A0%B4%E3%80%81%E9%81%8B%E5%96%B6%E3%81%8B%E3%82%89%E3%81%AE%E3%83%97%E3%83%AC%E3%82%BC%E3%83%B3%E3%83%88%E3%81%8C%E5%B1%8A%E3%81%8D%E3%81%BE%E3%81%97%E3%81%9F%E3%80%82%23%E3%81%93%E3%81%AE%E3%83%81%E3%83%A3%E3%83%B3%E3%82%B9%E3%82%92%E3%81%8A%E8%A6%8B%E9%80%83%E3%81%97%E3%81%AA%E3%81%8F%EF%BC%81%E3%80%82%0A%E8%A9%B3%E3%81%97%E3%81%84%E5%86%85%E5%AE%B9%E3%81%AF%E3%81%93%E3%81%A1%E3%82%89%E2%86%92https%3A%2F%2Farka-jp.ulugame.com%2Farka%2F%23%2FLuocha"
        );
      }
    },
    setShowLogin(state, show) {
      state.showLogin = show;
    },
    closeCard(state) {
      state.cardShow = false;
    },
    closeCopy(state, isCopy) {
      state.isCopy = isCopy;
    },
    setShowList(state, showList) {
      state.showList = showList;
    },
    setShowLis(state, index) {
      //let showList = [...state.showList];
      state.showList[index].show = true;
      state.showQuestion = true;
    },
    setUid(state, uid) {
      state.uid = uid;
    },
    setParams(state, params) {
      state = { ...state, ...params };
    },
    showIntroduce(state, isIntroduce) {
      state.isIntroduce = isIntroduce;
    },
    setShowAward(state, showAward) {
      state.showAward = showAward;
    },
    setQ(state, showQ) {
      state.showQuestion = showQ;
    },
  },
  actions: {
    getSingInfo({ state }, params = {}) {
      //commit("setUid", { uid: params.uid });
      let a = useToast();
      //a(`${params.uid}##@${params.date}`, { position: POSITION.TOP_CENTER });
      let signature = getSignature(
        { uid: params.uid, timestamp: params.date, activityId: 1005 },
        params.date
      );
      state.showList = [];
      for (let i = 0; i < 7; i++) {
        state.showList.push({});
      }
      //a(`uuuuuuuu`, { position: POSITION.TOP_CENTER });
      new Promise( (resolve, reject) => {
        request({
          method: "post",
          url: "https://sdk.ulugame.com/v2/api/community/activity/getSignInfo",
          data: {
            uid: params.uid,
            timestamp: params.date,
            signature,
            activityId: 1005,
          },
        }).then(({ data: { code, data } }) => {
          resolve("a");
          //a("data", { position: POSITION.TOP_CENTER });
          if (code == 0 && data) {
            //lots.value = data[a[a.length - 1]];
            let q = state.showList;
            //let a = Object.keys(data);
            for (let key in data) {
              if (key) {
                q[Number(key) - 1].show = true;
              }
            }
            state.showList = q;
            //console.log(111, a, q);
            // if (needLast && a.length > 0) {
            //   q[Number(a[a.length - 1]) - 1].show = false;
            if (params.ind) {
              state.obj = data[params.ind];
            }

            // }
            //commit("setShowList", q);
            // state.showList = q;
          }
        }).catch(e => { 
            reject(e)
               a(e+'', { position: POSITION.TOP_CENTER });
        });
      });
    },
    async showCode({ state, dispatch }, { index, isNeed, toast }) {
      let date = new Date().getTime();
      if (isNeed === "1") {
        await dispatch("getSingInfo", { date, uid: state.uid, ind: index + 1 });
        state.isCopy = true;
        return;
      }
      let signature = getSignature(
        { uid: state.uid, timestamp: date, activityId: 1005 },
        date
      );
      if (
        state.showList &&
        state.showList.filter((item) => item.show).length >= index
      ) {
        let {
          data: { code, data },
        } = await request({
          method: "put",
          url: "https://sdk.ulugame.com/v2/api/community/activity/sign",
          data: {
            uid: state.uid,
            timestamp: date,
            signature,
            activityId: 1005,
          },
        });
            //toast("code:" + code);
        if (code == 0 && data) {
          //   if (data[index + 1]) {
          //     commit("setShowLis", index);
          //   }
          data[index + 1] && (state.isCopy = true);
          state.obj = data[index + 1];

          setTimeout(async () => {
            await dispatch("getSingInfo", {
              date,
              uid: state.uid,
            });
          }, 500);
        } else if (code) {
          toast && toast("キャンペーン対象期間外です");
        }
      } else {
        toast && toast("キャンペーン対象期間外です");
      }
    },
    async showItems({ state }, { toast }) {
      state.isShowItem = true;
      setTimeout(() => {
        state.isShowItem = false;
      }, 5000);
      let date = new Date().getTime();
      if (!state.uid || state.uid.length != 19) {
        toast(state.codeList[40010]);
        return;
      }
      let signature = getSignature({ uid: state.uid, timestamp: date }, date);
      let {
        data: { code, data },
      } = await request({
        method: "put",
        url: "https://sdk.ulugame.com/v2/api/community/activity/lottery",
        data: {
          uid: state.uid,
          timestamp: date,
          signature,
        },
      });
      if (code == 0) {
        setTimeout(() => {
          state.cardShow = true;
        }, 7000);

        state.lots = data;
      } else if (code) {
        toast(this.codeList[code]);
      }
    },
    async submit({ state, dispatch }, { toast }) {
      if (!state.uid || state.uid.length != 19) {
        toast(state.codeList[40010]);
        return;
      }
      
      try {
        if (localStorage.getItem(`${state.uid}#`)) {
          let a = JSON.parse(localStorage.getItem(`${state.uid}#`));
          if (a) {
            state.shareList = a;
          }
        }
      } catch (error) {
        console.log(error);
        // alert(error)
        //toast(error);
      }
      state.showLogin = false;
      //toast('success');
      if(state.date>1649929506156){
        toast(state.codeList[40701]);
         return;
       }
      let date = new Date().getTime();
      ///api/community/activity/sign
      await dispatch("getSingInfo", { date, uid: state.uid });
    },
    async showLottery({ state }) {
      let a = useToast();
      let toast = (message = "") => {
        a(message, { position: POSITION.TOP_CENTER });
      };
      state.isShowLottery = true;
      setTimeout(() => {
        state.isShowLottery = false;
        state.showAward = true;
      }, 5000);
      let date = new Date().getTime();
      if (!state.uid || state.uid.length != 19) {
        toast(state.codeList[40010]);
        return;
      }
      let signature = getSignature(
        { uid: state.uid, timestamp: date, activityId: 1005 },
        date
      );
      let {
        data: { code, data },
      } = await request({
        method: "put",
        url: "https://sdk.ulugame.com/v2/api/community/activity/lottery",
        data: {
          uid: state.uid,
          timestamp: date,
          signature,
          activityId: 1005,
        },
      });
      if (code == 0) {
        state.lots = data;
      } else if (code) {
        //toast(state.codeList[code]);
        console.log(code);
      }

      console.log(code, data, state.lots);
    },
    switchWeb({ state }, status) {
      console.log(state);
      if (status == 1) {
        window.open("https://apps.apple.com/jp/app/id1570592063");
      } else {
        window.open(
          "https://play.google.com/store/apps/details?id=com.ulugame.arkajp.google"
        );
      }
    },
  },
  getters: {
    showList: (state) => state.showList,
    isCopy: (state) => state.isCopy,
    obj: (state) => state.obj,
  },
};

export const phone = {
  state: () => ({}),
  mutations: {},
  actions: {},
  getters: {},
};

export const pc = {
  state: () => ({}),
  mutations: {},
  actions: {},
  getters: {},
};
