<script>
import { defineComponent, ref, reactive } from "vue";
import { request } from "../utils/request";
import { getSignature } from "../utils/index";
import { useToast, POSITION } from "vue-toastification";
import { mapActions, mapMutations } from "vuex";
import Clipboard from 'clipboard';
export default defineComponent({
  setup() {
    // const { proxy } = getCurrentInstance();
    // proxy.$router.afterEach(() => {
    //   console.log(88899);

    // });

    const toast = useToast();
    let codeList = {
      40111:"정확한 이메일을 입력해 주세요",
      40112:"이벤트가 종료되었습니다"
    };
    let showLogin = ref(true);
    let cardShow = ref(false);
    let isShowItem = ref(false);
    let dateList = reactive([
      1640966400000 + 24 * 3600 * 1000,
      1640966400000 + 24 * 3600 * 1000 * 2,
      1640966400000 + 24 * 3600 * 1000 * 3,
      1640966400000 + 24 * 3600 * 1000 * 4,
      1640966400000 + 24 * 3600 * 1000 * 5,
      1640966400000 + 24 * 3600 * 1000 * 6,
      1640966400000 + 24 * 3600 * 1000 * 7,
    ]);
    let aDate = ref(new Date().getTime());
    let uid = ref("");
    let lots = reactive({ value: {} });
    let aData = reactive({ value: {} });
    let isClick = ref(false);
    let days =ref([]);

    async function submit() {
      // if (!uid.value || uid.value.length != 19) {
      //   toast(codeList[40010], { position: POSITION.TOP_CENTER });
      //   return;
      // }
      let timedate = new Date().getTime()
    if(timedate>1663167599000){
      toast(codeList[40112], { position: POSITION.TOP_CENTER });
      return;
    }
      var reg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if(!reg.test(uid.value)){
        toast(codeList[40111], { position: POSITION.TOP_CENTER });
        return;
      }else{
        this.showLogin = false;
      }
    //   let date = new Date().getTime();
    //   await this.getSingInfo({ date, uid: uid.value });
    //   localStorage.setItem('uid',uid.value)
    //   this.showLogin = false;
    //   console.log(uid.value);
    //   let signature = getSignature({ uid: uid.value, timestamp: date, activityId:1010 }, date);
    //   const {data:{code,data}} = await AInformation({
    //    url:"https://sdk.ulugame.com/v2/api/community/activity/getSignInfo",
    //    data:{
    //      uid:uid.value,
    //      activityId:1010,
    //      timestamp:date,
    //      signature
    //    }
    //  })
    //  if(code == 0){
    //    console.log(data)
    //    let dayls=[]
    //    for(let key in data){
    //      console.log(key)
    //      days.value.push(data[key].tierId)
    //      dayls.push(data[key].codeId)
    //      console.log(dayls);
    //    }
    //    console.log(dayls[dayls.length-1])
    //    localStorage.setItem('Tinputs',dayls[dayls.length-1])
    //    console.log(days.value)
    //  }
     
    }

    let isIntroduce = ref(false);
    function showIntroduce(value) {
      // showLogin.value = false;
      isIntroduce.value = value;
    }

    const getImageUrl = (name) => {
      return require(`../assets/${name}`);
    };

    function closeCard() {
      cardShow.value = false;
    }
    async function showItem() {
      isShowItem.value = true;
      setTimeout(() => {
        isShowItem.value = false;
      }, 8000);
      let date = new Date().getTime();
      if (!uid.value || uid.value.length != 19) {
        toast(codeList[40010], { position: POSITION.TOP_CENTER });
        return;
      }
      let signature = getSignature({ uid: uid.value, timestamp: date }, date);
     
      let {
        data: { code, data },
      } = await request({
        method: "put",
        url: "https://sdk.ulugame.com/v2/api/community/activity/lottery",
        data: {
          uid: uid.value,
          timestamp: date,
          signature,
        },
      });
      if (code == 0) {
        setTimeout(() => {
          cardShow.value = true;
        }, 7000);
        lots.value = data;
      } else if (code) {
        this.toast(codeList[code], { position: POSITION.TOP_CENTER });
      }

      console.log(code, data);
    }
    function copy() {
      var range = {};
      var div = document.getElementById("sharedurl");
      if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(div);
        range.select();
      } else if (window.getSelection) {
        var selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(div);
        selection.removeAllRanges();
        selection.addRange(range);
        // if(selection.setBaseAndExtent){
        //         selection.setBaseAndExtent(text, 0, text, 1);
        //     }
      } else {
        console.warn("none");
      }
      // document.execCommand("selectall");
      let a = document.execCommand("Copy"); // 执行浏览器复制命令
      console.log(8899, a);
    }
    function showIt(index, isNeed) {
      let a = useToast();
      let toast = (message = "") => {
        a(message, POSITION.TOP_CENTER);
      };
      this.showCode({ index, isNeed, toast });
    }
    return {
      showLogin,
      submit,
      isIntroduce,
      showIntroduce,
      getImageUrl,
      closeCard,
      cardShow,
      showItem,
      isShowItem,
      uid,
      dateList,
      aDate,
      showIt,
      days,
      copy,
      lots,
      toast,
      codeList,
      aData,
      isClick,
    };
  },
  data () {
      return {
        showModal:false,
        showModal3:false,
        showModal1:false,
        showModal2:false,
        CopySuccess:false,
        inputs:'',
        Model2inputs:'2XOVERLORD',
        codes:[],
        shareCodes:[],
        codeID:"",
        share:false,
        prodIndex:'',
       current: 0, // 当前索引值
      speed: 200, // 时间->速度
      diff: 15, // 基数
      award: {}, // 抽中的奖品
      time: 0, // 当前时间戳
      timer: null, // 定时器
      Active:false,
      UidgIDD:''//领取框
      }
  },
  async mounted() {
  
     
  },
 created(){
  
  },
  methods: {
    ...mapActions("common", ["getSingInfo", "showCode"]),
    ...mapMutations("common", ["setUid", "closeCopy","openShare"]),
    // 猜图领取奖励
    subframe(){
      const toast = useToast();
      let codeList = {
      40111:"연상되는 콜라보레이션의 제목을 입력해 주세요.",
      40112:"이벤트가 종료되었습니다"
    };
    let timedate = new Date().getTime()
    if(timedate>1663167599000){
      toast(codeList[40112], { position: POSITION.TOP_CENTER });
      return;
    }
      if(this.UidgIDD==''){
        toast(codeList[40111], { position: POSITION.TOP_CENTER });
        // this.showModal2=false
      }else{
        this.showModal2=true
      }
    },
    showModelClose(){
      this.showModal1=false
    }, 
  //关闭弹框
  showModel2Close(){
    this.showModal2=false
  },
// 关闭复制成功后的弹窗
    copyClose(){
      this.CopySuccess=false
    },
  //  复制
    copysPopup(){
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e =>{
        console.log('复制成功')
        console.log(e)
        clipboard.destroy()
        this.CopySuccess=true
        this.showModal2=false
      })
      clipboard.on('error', e=>{
        //不支持复制
        console.log('浏览器不支持自动复制')
        //释放内存
        console.log(e)
        clipboard.destroy()
      })
    },
  },
  watch: {
    uid(val) {
      if (val && val.length == 19) {
        this.setUid(val);
      }
    },
  },
  computed: {
 
  },
});
</script>
<template>
  <div>
    <div @click="close" class="box8" v-show="showModal">
    </div>
    <div class="bg_BK">
    <div class="bg bg_login">
      <!-- <img src="../assets/SeepicturePC/login_title.png" class="banner_title" /> -->
        <div></div>
        <input placeholder="애니메이션 이름을 입력해주세요." type="text" v-model="UidgIDD">
        <img src="../assets/SeepicturePC/login_btn.png"
         @click="subframe"
         alt="">
        <div class="ole_time">
          <p>종료 기간：2022년09월14일 23:59 까지</p>
        </div>
        <!-- <img src="../assets/SeepicturePC/leftpeo.png" alt=""> -->
        <!-- 领取奖励 -->
      <div class="showModal2" v-show="showModal2">
         <img src="../assets/SeepicturePC/showModel2_title.png" alt="">
         <img @click="showModel2Close" src="../assets/2x/showModel2_x.png" alt="">
         <img src="../assets/SeepicturePC/showModel2_content2.png" alt="">
         <div>
         <input type="text" readonly v-model="Model2inputs">
         <img class="tag-read" :data-clipboard-text="this.Model2inputs" @click="copysPopup" src="../assets/SeepicturePC/showModel1_btn.png" alt="">
         </div>
         <!-- <img src="../assets/SeepicturePC/login_time.png" alt=""> -->
          <p>유효기간：2022년09월14일 까지</p>
      </div>
      <!-- 复制成功之后的弹窗 -->
      <div v-show="CopySuccess" class="CopySuccess">
     
      <img @click="copyClose" src="../assets/SeepicturePC/copy_close.png" alt="">
    </div>
      <!-- 奖励背景 -->
      <div class="box8_showModel2" @click="showModel2Close" v-show="showModal2">
    
      </div>
      <!-- 复制成功后的弹窗背景 -->
      <div class="box8_CopySuccess" @click="copyClose" v-show="CopySuccess">
    
      </div>
      <div class="bg-login" v-show="showLogin">
        <div>
          <img src="../assets/SeepicturePC/Logo.png" class="title_login_img" />
          <div>
            <div class="fleexs">
              <input placeholder="메일을 기재해 주세요" v-model="uid" />
              <img
                src="../assets/SeepicturePC/UIDfa.png"
                class="info"
              />
              <img
                src="../assets/SeepicturePC/login_btn2.png"
                class="button"
                @click="submit"
              />
            </div>
          </div>
      </div>
      </div>
      
      <div class="flex-row hCenter "></div> 
    </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
img {
  display: block;
}
.bg-common {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.bg_BK{
  width: 100%;
  height: 100vh;
  background-color: black;
}
.bg {
  width: 100%;
  height: 10.8rem;
  .bg-common;
  background-image: url("../assets/SeepicturePC/BG.png");
  position: relative;
  .banner_title{
    width: 10.71rem;
    height: 1.69rem;
    position: absolute;
    top: 1.95rem;
    left: 4.27rem;
  }
  &>:nth-child(2){
    width: 4.56rem;
    height: 0.53rem;
    position: absolute;
    font-size: 0.25rem;
    border-radius: 8px;
    outline: none;
    left: 5.03rem;
    top: 5.23rem;
  }
  &>:nth-child(3){
    width: 1.22rem;
    height: 0.45rem;
    cursor: pointer;
    position: absolute;
    left: 6.71rem;
    top: 6rem;
  }
  &>:nth-child(4){
   p{
    font-size: 0.24rem;
    display: block;
    color: grey;
    position: absolute;
     top: 7.03rem;
    left: 5.19rem;
   }
    
   
  }
  &>:nth-child(5){
    width: 6.89rem;
    height: 7.58rem;
    position: absolute;
    top: 3.05rem;
    left: 10.05rem;
  }
    .showModal2{
    position: fixed;
    top: 3.05rem;
    left: 6.34rem;
    width: 6.59rem;
    height: 3.79rem;
    z-index: 99999;
    background: url(../assets/SeepicturePC/showModel2.png);
    // background-color: red;
    background-repeat: no-repeat;
    background-size: 100% 100%;
   &>:nth-child(1){
    width: 4.27rem;
    height: 0.69rem;
    position: absolute;
    left: 5.78vw;
    top: 2.5vw;
   }
   &>:nth-child(2){
    width: 0.42rem;
    height: 0.42rem;
    position: absolute;
    left: 5.9rem;
    top: 0.2rem;
    cursor: pointer;
   }
   &>:nth-child(3){
    width: 2.2rem;
    height: 0.22rem;
    position: absolute;
    left: 0.82rem;
    top: 1.54rem;
   }
     &>:nth-child(4){
      &>:nth-child(1){
        position: absolute;
        top: 1.8rem;
        left: 0.82rem;
        width: 3.4rem;
        height: 0.45rem;
        border: none;
        outline: none;
        font-size: 0.2rem;
        border-radius: 5px;
      }
      &>:nth-child(2){
        position: absolute;
        top: 1.8rem;
        left: 4.4rem;
        width: 1.1rem;
        height: 0.48rem;
        cursor: pointer;
      }
    }
    &>:nth-child(5){
      width: 4.15rem;
      height: 0.3rem;
      position: absolute;
      font-size: 0.22rem;
      color: #fff;
      top: 2.8rem;
      left: 1rem;
    }
  }
    .CopySuccess{
    background: url(../assets/SeepicturePC/copy_success.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 5.5rem;
    height: 1.2rem;
    position: fixed;
    top: 4.7rem;
    left: 7rem;
    z-index: 9999;
    img{
      width: 0.5rem;
      height: 0.15rem;
      position: absolute;
      left: 4.8rem;
      top: 0.82rem;
      cursor: pointer;
    }
  }
}
.bg_login .bg-login {
  cursor: pointer;
  .bg-common;
  // background-position: top left;
  // background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../assets/SeepicturePC/login_bg.png");
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100vh;
  top: 0;
  .title_login_img{
    width: 2.24rem;
    height: 1.16rem;
    // width: 224px;
    // height: 116px;
    position: absolute;
    // top: 0.12rem;
    top: 1vh;
    left: 0.17rem;
    // left: 17px;
  }
  .fleexs{
    & > :nth-child(1){
      width: 5.1rem;
      height: 0.69rem;
      // width: 510px;
      // height: 69px;
      position: absolute;
      left: 5.96rem;
      // left: 596px;
      font-size: 0.3rem;
      // top: 5.48rem;
      top: 50.7vh;
      box-sizing: border-box;
      outline: none
    }
    & > :nth-child(2){
      width: 4.73rem;
      height: 0.31rem;
      // width: 473px;
      // height: 31px;
      position: absolute;
      left: 5.98rem;
      // left: 598px;
      // top: 6.25rem;
      top: 58.5vh;
      
    }
    & > :nth-child(3){
      width: 2.11rem;
      height: 0.86rem;
      // width: 211px;
      // height: 86px;
      position: absolute;
      left: 11.19rem;
      // left: 1119px;
      // top: 5.4rem;
      top: 49.8vh;
      margin-left: 0.16vw;
      
    }
  }
}

.introduce {
  width: 52.13vw;
  height: 31.92vw;
  position: absolute;
  z-index: 100;
  left: 31%;
  top: 58%;
  transform: translate(-50%, -50%);
}
.introduces{
  width: 55.10vw;
  height: 30.26vw;
  position: absolute;
  left: 69%;
  top: 58%;
  transform: translate(-50%, -51%);
}
.introduce3{
  width: 100%;
  height: 100%;
}
.introduce4{
  width: 16.87vw;
  height: 8.54vw;
  position: absolute;
  left: 41.61vw;
  top: 4.54vw;
}

.box8_showModel2{
   width: 100vw;
  height: 100vh;
  background-image: url("../assets/2x/bgchou.png");
  opacity: 0.7;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.box8_CopySuccess{
    width: 100vw;
  height: 100vh;
  background-image: url("../assets/2x/bgchou.png");
  opacity: 0.7;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
</style>
