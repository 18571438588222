<script>
import { defineComponent, onMounted } from "vue";
import SeepicturePC from "../components/SeepicturePC.vue";
// import SeePicturephone from "../components/SeePicturephone.vue";

export default defineComponent({
  components: { SeepicturePC },
  setup() {
    const isPhone = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    onMounted(() => {
      document.title = "2X 최초의 콜라보 시작! 콜라보한 이름을 맞춰보세요？";
    });
    return { isPhone };
  },
});
</script>
<template>
  <!-- <SeePicturephone></SeePicturephone> -->
  <SeepicturePC></SeepicturePC>
</template>
<style lang="less" scoped></style>
