const crypto = require("crypto");

export function getSignature(initParam, time) {
  var list = Object.keys(initParam).sort();
  var rawString = "";
  for (var i = 0; i < list.length; i++) {
    rawString += initParam[list[i]];
  }
  var offset = time % 17;
  if (rawString.length > offset) {
    rawString =
      rawString.substring(offset, rawString.length) +
      rawString.substring(0, offset);
  }
  var md5 = crypto.createHash("md5");
  md5.update(rawString);
  return md5.digest("hex").toUpperCase();
}
/**
 * 复制codeid
 */
export function copy() {
  var range = {};
  var div = document.getElementById("sharedurl");
  if (document.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElementText(div);
    range.select();
  } else if (window.getSelection) {
    var selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(div);
    selection.removeAllRanges();
    selection.addRange(range);
    // if(selection.setBaseAndExtent){
    //         selection.setBaseAndExtent(text, 0, text, 1);
    //     }
  } else {
    console.warn("none");
  }
  // document.execCommand("selectall");
  let a = document.execCommand("Copy"); // 执行浏览器复制命令
  console.log(8899, a);
}
export const getImageUrl = (name) => {
  return require(`../assets/${name}`);
};
