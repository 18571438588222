import { createStore } from "vuex";
import { common, phone, pc } from "./modules/index";
// 创建一个新的 store 实例
const store = createStore({
  modules: {
    common,
    phone,
    pc,
  },
});

export default store;
