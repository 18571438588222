import { createRouter, createWebHashHistory } from "vue-router";
import SeePicture from "./pages/SeePicture.vue"
import OverLord from "./pages/OverLord.vue"
export default createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path:"/",
      component:SeePicture
    },
    {
      path:"/OverLord",
      component:OverLord
    },
    {
      path: '/404',
      name: '404',
      component: () => import('./components/404.vue')
  },
  {
      path: '/:pathMatch(.*)',
      redirect: '/404'
  }
  ],
});
