<script>
import { defineComponent, ref, reactive } from "vue";
import { request,AInformation,requests } from "../utils/request";
import { getSignature } from "../utils/index";
import { useToast, POSITION } from "vue-toastification";
import { mapActions, mapMutations } from "vuex";
import Clipboard from 'clipboard';
export default defineComponent({
  setup() {
    const toast = useToast();
    let codeList = {
      40111:"정확한 이메일을 입력해 주세요",
      40112:"설정에 오류가 발생했습니다.",
      40703:"이벤트가 종료되었습니다"
    };
    let showLogin = ref(true);
    
    let cardShow = ref(false);
    let isShowItem = ref(false);
    let dateList = reactive([
      1640966400000 + 24 * 3600 * 1000,
      1640966400000 + 24 * 3600 * 1000 * 2,
      1640966400000 + 24 * 3600 * 1000 * 3,
      1640966400000 + 24 * 3600 * 1000 * 4,
      1640966400000 + 24 * 3600 * 1000 * 5,
      1640966400000 + 24 * 3600 * 1000 * 6,
      1640966400000 + 24 * 3600 * 1000 * 7,
    ]);

    let aDate = ref(new Date().getTime());
    let uid = ref("");
    let lots = reactive({ value: {} });
    let aData = reactive({ value: {} });
    let isClick = ref(false);
    let days =ref([]);

    async function submit() {
      let timedate = new Date().getTime()
    if(timedate>1663772399000){
      toast(codeList[40703], { position: POSITION.TOP_CENTER });
      return;
    }
      var reg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if(!reg.test(uid.value)){
        toast(codeList[40111], { position: POSITION.TOP_CENTER });
        return;
      }
      this.submits()
      let date = new Date().getTime();
      // await this.getSingInfo({ date, uid: uid.value });
      localStorage.setItem('uid',uid.value)
      
      console.log(uid.value);
      let signature = getSignature({ uid: uid.value, timestamp: date, activityId:1013 }, date);
      const {data:{code,data}} = await AInformation({
       url:"https://sdk.ulugame.com/v2/api/community/activity/getSignInfo",
       data:{
         uid:uid.value,
         activityId:1013,
         timestamp:date,
         signature
       }
     })
     if(code == 0){
       console.log(data)
       this.showLogin = false;
     
       for(let key in data){
         console.log(key)
         days.value.push(data[key].tierId)
        //  console.log(dayls);
       } 
       console.log(days.value)
     }
     if(code == 500){
      toast(codeList[40112], { position: POSITION.TOP_CENTER });
     }
     if(code == 1){
      toast(codeList[40112], { position: POSITION.TOP_CENTER });
     }
     if(code == 40703){
      toast(codeList[40703], { position: POSITION.TOP_CENTER });
     }
    }
    //签到
    async function submits() {
      // let timedate = new Date().getTime()
    // if(timedate>1663772399000){
    //   toast(codeList[40703], { position: POSITION.TOP_CENTER });
    //   return;
    // }
      var reg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if(!reg.test(uid.value)){
        toast(codeList[40111], { position: POSITION.TOP_CENTER });
        return;
      }
      let date = new Date().getTime();
      ///api/community/activity/sign
      localStorage.setItem('uid',uid.value)
      
      let signature = getSignature({ uid: uid.value, timestamp: date, activityId:1012 }, date);
      const {data:{code,data}} = await AInformation({
       url:"https://sdk.ulugame.com/v2/api/community/activity/getSignInfo",
       data:{
         uid:uid.value,
         activityId:1012,
         timestamp:date,
         signature
       }
     })
     if(code == 0){
       console.log(data)
       this.showLogin = false;
         for(let key in data){
         console.log(key)
         console.log(data[key].giftContent);
         console.log(data[key].codeId);
         this.recevergrey=data[key].codeId;
         
         if(key){
          this.bgred=false
         }
       }
     }
     if(code == 500){
      toast(codeList[40112], { position: POSITION.TOP_CENTER });
     }
     if(code == 1){
      toast(codeList[40112], { position: POSITION.TOP_CENTER });
     }
     if(code == 40703){
      toast(codeList[40703], { position: POSITION.TOP_CENTER });
     }
    }
    let isIntroduce = ref(false);
    function showIntroduce(value) {
      // showLogin.value = false;
      isIntroduce.value = value;
    }

    const getImageUrl = (name) => {
      return require(`../assets/${name}`);
    };

    function closeCard() {
      cardShow.value = false;
    }
    async function showItem() {
      isShowItem.value = true;
      setTimeout(() => {
        isShowItem.value = false;
      }, 8000);
      let date = new Date().getTime();
      if (!uid.value || uid.value.length != 19) {
        toast(codeList[40010], { position: POSITION.TOP_CENTER });
        return;
      }
      let signature = getSignature({ uid: uid.value, timestamp: date }, date);
     
      let {
        data: { code, data },
      } = await request({
        method: "put",
        url: "https://sdk.ulugame.com/v2/api/community/activity/lottery",
        data: {
          uid: uid.value,
          timestamp: date,
          signature,
        },
      });
      if (code == 0) {
        setTimeout(() => {
          cardShow.value = true;
        }, 7000);

        lots.value = data;
      } else if (code) {
        this.toast(codeList[code], { position: POSITION.TOP_CENTER });
      }
      console.log(code, data);
    }
    function copy() {
      var range = {};
      var div = document.getElementById("sharedurl");
      if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(div);
        range.select();
      } else if (window.getSelection) {
        var selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(div);
        selection.removeAllRanges();
        selection.addRange(range);
        // if(selection.setBaseAndExtent){
        //         selection.setBaseAndExtent(text, 0, text, 1);
        //     }
      } else {
        console.warn("none");
      }
      // document.execCommand("selectall");
      let a = document.execCommand("Copy"); // 执行浏览器复制命令
      console.log(8899, a);
    }
    function showIt(index, isNeed) {
      let a = useToast();
      let toast = (message = "") => {
        a(message, POSITION.TOP_CENTER);
      };
      this.showCode({ index, isNeed, toast });
    }
    return {
      showLogin,
      submit,
      submits,
      isIntroduce,
      showIntroduce,
      getImageUrl,
      closeCard,
      cardShow,
      showItem,
      isShowItem,
      uid,
      dateList,
      aDate,
      showIt,
      days,
      copy,
      lots,
      toast,
      codeList,
      aData,
      isClick,
    };
  },
  data () {
      return {
        showModal1:false, //分享领取
        CopySuccess:false, //复制成功后的弹窗
        showModal2:false, //抽奖弹框
        showmodel3:false, //视频
         cat: 51,
        isAllowClick: true, //是否能够点击
        start_deg: 0, //初始旋转角度
        rotate_deg: 0, //将要旋转的角度
        rotate: 0, //实际偏移角度
        rotate_transition: "transform 3s ease-in-out", //初始化选中的过度属性控制
        times: null,
        priseId: 80,
        productIndex:0,
        inputs:'',
        Model1inputs:'', 
        Model2inputs:'',
        codes:[],
        codes2:[],
        shareCodes:[],
        codeID:"",
        share:false,
        prodIndex:'',
      Active:false,
      UidgIDD:'',//领取框
      bgred:true,
      recevergrey:''
      }
  },
  async mounted() {
  
     
  },
 created(){
  
  },
  methods: {
    ...mapActions("common", ["getSingInfo", "showCode"]),
    ...mapMutations("common", ["setUid", "closeCopy","openShare"]),
    //关闭视频弹框
    close3(){
      this.showmodel3=false
    },
    //打开视频弹框
    playVideo(){
      this.showmodel3=true
    },
    // 关闭领取弹框
    showModel1Close(){
      this.showModal1=false
    },
    showModel2Close(){
      this.showModal2=false
    },
    //签到
   async receives(){
    const toast = useToast();
      let codeList = {
      40300:"서버에 대한 요청 횟수가 너무 빈번합니다",
      40707:"이벤트에 중복 참여 하셨습니다.",
      40708:"금일 이벤트 참여 완료하였습니다.",
      40709:"설정에 오류가 발생했습니다.",
      40703:"이벤트가 종료되었습니다",
      40702:"현재 이벤트 미오픈 상태입니다."
    };
    let timedate = new Date().getTime()
    if(timedate>1663772399000){
      toast(codeList[40703], { position: POSITION.TOP_CENTER });
      return;
    }
      let uuid = localStorage.getItem('uid')
      let date = new Date().getTime();
      let signature = getSignature({ uid: uuid, timestamp: date, activityId:1012 }, date);
      const {data:{code,data}} = await request({
       url:"https://sdk.ulugame.com/v2/api/community/activity/share",
       data:{
         uid:uuid,
         activityId:1012,
         timestamp:date,
         signature
       }
     })
     if(code == 0){
       console.log(data)
       this.showModal1=true
         for(let key in data){
         console.log(key)
         console.log(data[key].giftContent);
         this.Model1inputs=data[key].codeId
         console.log(this.shareList);
       }
     }
      this.submits();
      if(code ==40300){
        toast(codeList[40300], { position: POSITION.TOP_CENTER });
      }
      if(code ==40707){
        toast(codeList[40707], { position: POSITION.TOP_CENTER });
      }
      if(code ==40010){
        toast(codeList[40010], { position: POSITION.TOP_CENTER });
      }
      if(code ==40703){
        toast(codeList[40703], { position: POSITION.TOP_CENTER });
      }
      if(code ==40702){
        toast(codeList[40702], { position: POSITION.TOP_CENTER });
      }
      if(code==500){
        toast(codeList[40709], { position: POSITION.TOP_CENTER });
      }
      if(code==1){
        toast(codeList[40709], { position: POSITION.TOP_CENTER });
      }
    },
    //置灰领取
    receivesGrey(){
      this.showModal1=true
      this.Model1inputs=this.recevergrey
    },
       async stopbtn(){
     const flag = await this.getPrise()
     
    //  this.showModal=true
     if (this.isAllowClick) {
      //  this.rotaTime();
        clearInterval(this.times);
        console.log("async this.start_deg", this.start_deg);
        flag && this.rotating();
      }
    },
     async getPrise(){
     //获取奖品
     const toast = useToast();
      let codeList = {
      40300:"서버에 대한 요청 횟수가 너무 빈번합니다",
      40707:"이벤트에 중복 참여 하셨습니다.",
      40708:"금일 이벤트 참여 완료하였습니다.",
      40709:"설정에 오류가 발생했습니다.",
      40703:"이벤트가 종료되었습니다",
      40702:"현재 이벤트 미오픈 상태입니다."
    };
    let timedate = new Date().getTime()
    if(timedate>1663772399000){
      toast(codeList[40703], { position: POSITION.TOP_CENTER });
      return;
    }
      let uuid = localStorage.getItem('uid')
      console.log(uuid);
      let date = new Date().getTime();
      let signature = getSignature({ uid:uuid, timestamp: date,activityId: 1013 }, date);
     let {data:{code,data}} = await requests({
       url:"https://sdk.ulugame.com/v2/api/community/activity/lotteryCode",
       data:{
         uid:uuid,
         activityId:1013,
         timestamp:date,
         signature
       }
     })
       if(code == 0){
        console.log(data)
       this.codes = data
       for(let key in this.codes){
        this.Model2inputs=this.codes[key].codeId

        this.prodIndex=this.codes[key].tierId

        // localStorage.setItem('Tinputs',this.inputs)
       }
       setTimeout(()=>{
        this.showModal2=true
        },3300)
       return 1
     }else{
      if(code == 40300){
        toast(codeList[40300], { position: POSITION.TOP_CENTER });
      }
      if(code == 40707){
        this.showModal2=true
        console.log(data)
        let dayls=[]
        this.codes2 = data
        for(let key in this.codes2){
        // this.Model2inputs=this.codes2[key].codeId
        dayls.push(this.codes2[key].codeId)
       }
       this.Model2inputs=dayls[dayls.length-1]
       console.log(dayls[dayls.length-1]);
      }
      if(code ==40010){
        toast(codeList[40010], { position: POSITION.TOP_CENTER });
      }
      if(code ==40703){
        toast(codeList[40703], { position: POSITION.TOP_CENTER });
      }
      if(code ==40702){
        toast(codeList[40702], { position: POSITION.TOP_CENTER });
      }
      if(code==500){
        toast(codeList[40709], { position: POSITION.TOP_CENTER });
      }
      if(code==1){
        toast(codeList[40709], { position: POSITION.TOP_CENTER });
      }
       return 0
     }
     
    },
        rotating() {
      let rand_circle = 5; //默认多旋转5圈
    let randomArr=[24,78,130,180,233,283,335]
      this.productIndex = Math.trunc(Math.random()*7)
      if(this.prodIndex==1){
        this.productIndex=6
      }
      if(this.prodIndex==2){
        this.productIndex=5
      }
      if(this.prodIndex==3){
        this.productIndex=4
      }
      if(this.prodIndex==4){
        this.productIndex=3
      }
      if(this.prodIndex==5){
        this.productIndex=2
      }
      if(this.prodIndex==6){
        this.productIndex=1
      }
      if(this.prodIndex==7){
        this.productIndex=0
      }
      let randomDeg = randomArr[this.productIndex];//获取0到359之间的随机度数,可以指定度数对准某个产品。
      console.log(this.productIndex)
      console.log(this.prodIndex)
         let deg =
       Number((this.start_deg +
        (360 * rand_circle + randomDeg) -
        (this.start_deg % 360)).toFixed(1)); //将要旋转的度数
        console.log(this.start_deg,rand_circle,randomDeg,this.start_deg);
      this.start_deg = deg; //下一次旋转的开始度数
      this.rotate_deg = "rotate(" + deg + "deg)";
      this.rotate =((deg % 360).toFixed(1)*1000)/1000; //实际偏移的度数
      console.log("this.rotate", deg, this.rotate);
    },
    rotaTime() {
      let deg = 0;
      this.times = setInterval(() => {
        deg = deg + 60;
        this.rotate_deg = "rotate(" + deg + "deg)";
        this.start_deg = deg;
        console.log(" this.start_deg", this.start_deg);
      }, 100);
    },
  //  复制
    copysPopup(){
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e =>{
        console.log('复制成功')
        console.log(e)
        clipboard.destroy()
        this.CopySuccess=true
        this.showModal1=false
        this.showModal2=false
      })
      clipboard.on('error', e=>{
        //不支持复制
        console.log('浏览器不支持自动复制')
        //释放内存
        console.log(e)
        clipboard.destroy()
      })
    },
    // 关闭复制弹框
    copyClose(){
      this.CopySuccess=false
    }
  },
  watch: {
    uid(val) {
      if (val && val.length == 19) {
        this.setUid(val);
      }
    },
  },
  computed: {
 
  },
});
</script>

<template>
  <div>
    <div class="bg_BK">
   <div class="fwaTid_BG">
    
   </div>
    <div class="bg">
      <div class="old_time">
        <p>2022년9월15일 0:00 ~ 2022년9월21일 23:59</p>
      </div>
      <div class="bg-login" v-show="showLogin">
        <div>
          <img src="../assets/SeepicturePC/Logo.png" class="title_login_img" />
          <div>
            <div class="fleexs">
              <input placeholder="메일을 기재해 주세요" v-model="uid" />
              <img
                src="../assets/SeepicturePC/UIDfa.png"
                class="info"
              />
              <img
                src="../assets/SeepicturePC/login_btn2.png"
                class="button"
                @click="submit"
              />
            </div>
          </div>
      </div>
      </div>
      
      <div class="flex-row hCenter "></div> 
    </div>
    <div class="bg_2">
      <!-- 领取背景 -->
        <div class="box8_recerve" @click="showModel1Close" v-show="showModal1">
    
        </div>
        <!-- 复制成功背景 -->
        <div class="box8_recerve" @click="copyClose" v-show="CopySuccess">
    
        </div>
        <!-- 抽奖背景 -->
        <div class="box8_recerve" @click="showModel2Close" v-show="showModal2">
    
        </div>
         <div class="box8_recerve" @click="close3" v-show="showmodel3">
    
        </div>
        <!--抽奖弹框  -->
          <div class="showModal1" v-show="showModal2">
            <img src="../assets/SeepicturePC/showModel2_title.png" alt="">
         <img @click="showModel2Close" src="../assets/2x/showModel2_x.png" alt="">
         <img src="../assets/SeepicturePC/showModel2_content2.png" alt="">
         <div>
         <input type="text" readonly v-model="Model2inputs">
         <img class="tag-read" :data-clipboard-text="this.Model2inputs" @click="copysPopup" src="../assets/SeepicturePC/showModel1_btn.png" alt="">
         </div>
         <!-- <img src="../assets/SeepicturePC/login_time.png" alt=""> -->
         <p>유효기간：2022년09월21일 까지</p>
        </div>
        <!-- 分享领取奖励的弹框 -->
        <div class="showModal1" v-show="showModal1">
            <img src="../assets/SeepicturePC/showModel2_title.png" alt="">
         <img @click="showModel1Close" src="../assets/2x/showModel2_x.png" alt="">
         <img src="../assets/SeepicturePC/showModel2_content2.png" alt="">
         <div>
         <input type="text" readonly v-model="Model1inputs">
         <img class="tag-read" :data-clipboard-text="this.Model1inputs" @click="copysPopup" src="../assets/SeepicturePC/showModel1_btn.png" alt="">
         </div>
         <!-- <img src="../assets/SeepicturePC/login_time.png" alt=""> -->
         <p>유효기간：2022년09월21일 까지</p>
        </div>
        <!-- 复制成功后的弹窗 -->
         <div v-show="CopySuccess" class="CopySuccess">
           <img @click="copyClose" src="../assets/2x/copy_close.png" alt="">
         </div>
        <div class="bg2_top">
        <div class="bg2_BG">
            <img @click="playVideo" src="../assets/2x/play_btn.png" alt="">
        </div>
        <div>
            <!-- <img src="../assets/2x/bg2_title.png" alt=""> -->
            <div></div>
            <div></div>
            <img v-if="bgred" @click="receives" src="../assets/2x/bg2_btn.png" alt="">
            <img v-else @click="receivesGrey" src="../assets/2x/bg2_grey.png" alt="">
        </div>
      </div>
      <!-- 视频弹框 -->
      <div class="bg2output" v-show="showmodel3">
        <div @click="close3">X</div>
        <video preload="auto" class="video" autoplay loop controls="controls" muted>
        <source src="~@/assets/2x/bg2output.mp4" type="video/mp4" />
        <source src="~@/assets/2x/bg2output.mp4" type="video/webm" />
        <source src="~@/assets/2x/bg2output.mp4" type="video/ogg" />
        <object
          data="movie.mp4"
          width="100%"
          height="100%"
          preload="auto"
        >
          <embed src="~@/assets/2x/bg2output.mp4" width="100%" height="100%" />
          
        </object>
      </video>
      </div>
      <div class="bg2_bottom">
            <div>
                <!-- <img src="../assets/2x/bg2_bottom.png" alt=""> -->
            </div>
            <div>
                <!-- <img src="../assets/2x/frame.png" alt=""> -->
                <img src="../assets/2x/pin2.png" alt="">
                <img src="../assets/2x/frame2.png" alt="">
                <div class="bg_well" ref="rotImg" :style="{ transform: rotate_deg, transition: rotate_transition }">
                    <img src="../assets/2x/ThePrize1.png" alt="">
                    <img src="../assets/2x/ThePrize2.png" alt="">
                    <img src="../assets/2x/ThePrize3.png" alt="">
                    <img src="../assets/2x/ThePrize4.png" alt="">
                    <img src="../assets/2x/ThePrize5.png" alt="">
                    <img src="../assets/2x/ThePrize6.png" alt="">
                    <img src="../assets/2x/ThePrize7.png" alt="">
                </div>
                <div class="bg_whells" ref="rotImg" :style="{ transform: rotate_deg, transition: rotate_transition }">
                  <div>
                   <img src="../assets/2x/grey1.png" v-show="days.indexOf(7)!=-1">
                   <img src="../assets/2x/grey7.png" v-show="days.indexOf(1)!=-1">
                   <img src="../assets/2x/grey6.png" v-show="days.indexOf(2)!=-1">
                   <img src="../assets/2x/grey5.png" v-show="days.indexOf(3)!=-1">
                   <img src="../assets/2x/grey4.png" v-show="days.indexOf(4)!=-1">
                   <img src="../assets/2x/grey3.png" v-show="days.indexOf(5)!=-1">
                   <img src="../assets/2x/grey2.png" v-show="days.indexOf(6)!=-1">
                  </div>
               </div>
                <div @click="stopbtn()">
                    <img src="../assets/2x/well_btn.png" alt="">
                </div>
            </div>
      </div>
    </div>
    <div class="bg_3">
        <div>
            <img src="../assets/2x/bg3_img1.png" alt="">
            <div>
                <a href="https://apps.apple.com/us/app/2x/id1572600077" target="_blank">
                    <img src="../assets/2x/bg3_appstore.png" alt="">
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.egames.sgdtskr.google" target="_blank">
                    <img src="../assets/2x/bg3_google.png" alt="">
                </a>
                <a href="http://onesto.re/0000756267" target="_blank">
                    <img src="../assets/2x/bg3_OneStore.png" alt="">
                </a>
                <a href="http://apps.samsung.com/appquery/appDetail.as?appId=com.ulugame.sgdtskr.galaxy" target="_blank">
                    <img src="../assets/2x/bg3_galaxystore.png" alt="">
                </a>
                
            </div>
          
        </div>
    </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
img {
  display: block;
}
.bg-common {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.bg_BK{
  width: 100%;
  height: 100%;
  max-width: 1920px;
  // margin: 0 auto;
  position: relative;
}
.fwaTid_BG{
    width: 100%;
    height: 28.22rem;
    background: url("../assets/2x/BG.png") center top/cover no-repeat;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%,0);
    z-index: -1;
}
.bg {
  width: 100%;
  height: 10rem;
  position: relative;
 .old_time{
  margin: 0 auto;
  padding-top: 8.81rem;
  p{
    font-size: 0.3rem;
    color: grey;
  }
 }
}
.bg-login {
  cursor: pointer;
  // .bg-common;
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../assets/SeepicturePC/login_bg.png");
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  top: 0;
  .title_login_img{
    width: 11.66vw;
    height: 10.74vh;
    position: absolute;
    top: 2vw;
    left: 2.44vw;
  }
  .fleexs{
    & > :nth-child(1){
      width: 21.15vw;
      height: 2.76vw;
      position: absolute;
      left: 37vw;
      font-size: 0.3rem;
      top: 52.94vh;
      outline: none
    }
    & > :nth-child(2){
      width: 20.63vw;
      height: 1.41vw;
      position: absolute;
      left: 37vw;
      top: 59.3vh;
      
    }
    & > :nth-child(3){
      width: 9.73vw;
      height: 3.82vw;
      position: absolute;
      left: 58.46vw;
      top: 52.3vh;
      margin-left: 0.36vw;
      
    }
  }
}
.bg_2{
    width: 100%;
    height: 15.15rem;
    .bg2_top{
        display: flex;
        .bg2_BG{
        width: 5.58rem;
        height: 6.29rem;
        // background: url("../assets/2x/poin_video.png");
        background-size: 100% 100%;
        margin-top: 0.9rem;
        margin-left: 3.22rem;
        img{
            width: 0.82rem;
            height: 0.97rem;
            margin-left: 4.33rem;
            margin-top: 5.28rem;
            cursor: pointer;
        }
    }
    &>:nth-child(2){
        &>:nth-child(1){
            width: 5.12rem;
        height: 2.24rem;
        margin-left: 0.61rem;
        margin-top: 0.8rem;
        }
        &>:nth-child(2){
            width: 7.09rem;
            height: 3.11rem;
            margin-left: 0.79rem;
        }
        &>:nth-child(3){
            width: 2.15rem;
            height: 0.92rem;
            margin-left: 3.31rem;
            margin-top: -0.2rem;
            cursor: pointer;
        }
        &>:nth-child(4){
            width: 2.15rem;
            height: 0.92rem;
            margin-left: 3.31rem;
            margin-top: -0.2rem;
            cursor: pointer;
        }
    }
    }
     .bg2output{
    position: fixed;
    top: 10.69vw;
    left: 28.16vw;
    z-index: 9;
    &>:nth-child(1){
      font-size: 0.4rem;
      color: #fff;
      position: absolute;
      left: 50vw;
      top: -0.1rem;
      z-index: 99999;
      cursor: pointer;
    }
    &>:nth-child(2){
       width: 50vw;
       height: 30vw;
       position: absolute;
    }
  }
    .bg2_bottom{
        display: flex;
        &>:nth-child(1){
             width: 5.33rem;
                height: 3.77rem;
                margin-left: 3.01rem;
                margin-top: 2.06rem;
           
        }
        &>:nth-child(2){
            background: url("../assets/2x/frame.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 6.38rem;
            height: 6.42rem;
            margin-top: 0.72rem;
            margin-left: 1.34rem;
            position: relative;
            &>:nth-child(1){
               width: 0.56rem;
               height: 0.74rem;
               position: absolute;
              left: 2.91rem;
              top: 0rem;
              z-index: 1;
            }
            &>:nth-child(2){
                width: 5.42rem;
                height: 5.42rem;
                position: absolute;
                top: 0.51rem;
                left: 0.48rem;
            }
            .bg_well{
                width: 5.37rem;
                height: 5.37rem;
                background: url("../assets/2x/weel.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                margin-left: 0.51rem;
                margin-top: 0.54rem;
                position: relative;
                &>:nth-child(1){
                    width: 1.1rem;
                    height: 1.36rem;
                    position: absolute;
                    left: 1.41rem;
                    top: 0.58rem;
                }
                &>:nth-child(2){
                    width: 1.22rem;
                    height: 1.64rem;
                    position: absolute;
                    left: 2.85rem;
                    top: 0.39rem;
                }
                &>:nth-child(3){
                    width: 1.72rem;
                    height: 1.02rem;
                    position: absolute;
                    left: 3.32rem;
                    top: 1.87rem;
                }
                &>:nth-child(4){
                    width: 1.3rem;
                    height: 1.57rem;
                    position: absolute;
                    left: 3.37rem;
                    top: 3.19rem;
                }
                &>:nth-child(5){
                    width: 0.93rem;
                    height: 1.74rem;
                    position: absolute;
                    left: 2.25rem;
                    top: 3.37rem;
                }
                &>:nth-child(6){
                    width: 1.79rem;
                    height: 1.43rem;
                    position: absolute;
                    left: 0.47rem;
                    top: 2.98rem;
                }
                &>:nth-child(7){
                    width: 1.45rem;
                    height: 1.15rem;
                    position: absolute;
                    left: 0.37rem;
                    top: 1.69rem;
                }
            }
            .bg_whells{
                width: 5.37rem;
                height: 5.37rem;
                // margin-left: 0.51rem;
                // margin-top: 0.54rem;
                z-index: 2;
                position: absolute;
                left: 0.51rem;
                top: 0.54rem;
                &>:nth-child(1){
                  &>:nth-child(1){
                    width: 2.16rem;
                    height: 2.73rem;
                    position: absolute;
                    left: 0.66rem;
                    top: 0rem;
                }
                &>:nth-child(2){
                    width: 2.15rem;
                    height: 2.73rem;
                    position: absolute;
                    left: 2.6rem;
                    top: 0rem;
                }
                &>:nth-child(3){
                    width: 2.7rem;
                    height: 2.31rem;
                    position: absolute;
                    left: 2.65rem;
                    top: 0.97rem;
                }
                &>:nth-child(4){
                    width: 2.67rem;
                    height: 2.46rem;
                    position: absolute;
                    left: 2.64rem;
                    top: 2.64rem;
                }
                &>:nth-child(5){
                    width: 2.38rem;
                    height: 2.73rem;
                    position: absolute;
                    left: 1.51rem;
                    top: 2.65rem;
                }
                &>:nth-child(6){
                    width: 2.65rem;
                    height: 2.47rem;
                    position: absolute;
                    left: 0.08rem;
                    top: 2.64rem;
                }
                &>:nth-child(7){
                    width: 2.73rem;
                    height: 2.33rem;
                    position: absolute;
                    left: 0rem;
                    top: 0.98rem;
                }
                }
                
            }
            &>:nth-child(5){
                img{
                    width: 1.15rem;
                    height: 1.15rem;
                    position: absolute;
                    top: 2.69rem;
                    left: 2.64rem;
                    cursor: pointer;
                    z-index: 2;
                }
            }
           
        }
    }
    .showModal1{
    position: fixed;
    top: 3.05rem;
    left: 6.34rem;
    width: 6.59rem;
    height: 3.79rem;
    z-index: 99999;
    background: url(../assets/SeepicturePC/showModel2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
   &>:nth-child(1){
    width: 4.27rem;
    height: 0.69rem;
    position: absolute;
    left: 5.78vw;
    top: 2.5vw;
   }
   &>:nth-child(2){
    width: 0.42rem;
    height: 0.42rem;
    position: absolute;
    left: 5.9rem;
    top: 0.2rem;
    cursor: pointer;
   }
   &>:nth-child(3){
    width: 2.2rem;
    height: 0.22rem;
    position: absolute;
    left: 0.82rem;
    top: 1.54rem;
   }
     &>:nth-child(4){
      &>:nth-child(1){
        position: absolute;
        top: 1.8rem;
        left: 0.82rem;
        width: 3.4rem;
        height: 0.45rem;
        border: none;
        outline: none;
        font-size: 0.4rem;
        border-radius: 5px;
      }
      &>:nth-child(2){
        position: absolute;
        top: 1.8rem;
        left: 4.4rem;
        width: 1.1rem;
        height: 0.48rem;
        cursor: pointer;
      }
    }
    &>:nth-child(5){
      width: 4.15rem;
      height: 0.3rem;
      position: absolute;
      font-size: 0.25rem;
      color: #fff;
      top: 2.8rem;
      left: 1rem;
    }
  }
    .CopySuccess{
    background: url(../assets/2x/copy_success.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 5.5rem;
    height: 1.2rem;
    position: fixed;
    top: 4.2rem;
    left: 7rem;
    z-index: 9999;
    img{
      width: 0.5rem;
      height: 0.15rem;
      position: absolute;
      left: 4.8rem;
      top: 0.82rem;
      cursor: pointer;
    }
  }
  .box8_recerve{
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/2x/bgchou.png");
  opacity: 0.7;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}
}
.bg_3{
    width: 100%;
    height: 100%;
    margin-left: 5.82rem;
    margin-top: 0.4rem;
    &>:nth-child(1){
         display: flex;
        &>:nth-child(1){
            width: 1.79rem;
            height: 1.79rem;
        }
        &>:nth-child(2){
            display: flex;
            margin-left: 0.56rem;
            margin-top: 0.12rem;
            flex-wrap: wrap;
            width: 7rem;
            &>:nth-child(1){
                img{
                     width: 2.56rem;
                     height: 0.69rem;
                     cursor: pointer;
                }
               
            }
            &>:nth-child(2){
                img{
                    width: 2.56rem;
                   height: 0.69rem;
                   cursor: pointer;
                }
                margin-left: 0.13rem;
            }
            &>:nth-child(3){
                img{
                    width: 2.56rem;
                    height: 0.69rem;
                    cursor: pointer;
                }
            }
            &>:nth-child(4){
                img{
                    width: 2.56rem;
                    height: 0.69rem;
                    cursor: pointer;
                }
                margin-left: 0.13rem;
                
            }
        }
    }
}
</style>
