/*
 * @Author: your name
 * @Date: 2021-12-21 12:41:24
 * @LastEditTime: 2022-03-01 15:58:08
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \hello\src\main.js
 */
import { createApp } from "vue";
import Toast from "vue-toastification";
// import vue from 'vue'
import "vue-toastification/dist/index.css";
import VueSocialSharing from "vue-social-sharing";
import router from "./router";
import App from "./App.vue";
import store from "./store/index";
import "./app.less";


// import Vconsole from "vconsole";
// const vConsole = new Vconsole();



const app = createApp(App);
app.use(Toast, {
  shareAppContext: true,
});
app.use(router);
app.use(store);
app.use(VueSocialSharing);
app.mount("#app");
// app.use(vConsole);