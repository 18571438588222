/*
 * @Author: your name
 * @Date: 2021-12-21 12:42:43
 * @LastEditTime: 2022-03-22 13:45:39
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \hello\src\utils\request.js
 */
import axios from "axios";
let a = axios.create({
  baseURL: "https://sdk.ulugame.com/v2",
});
export const request = ({
  method = "post",
  data = {},
  url = "",
  params = {},
}) => {
  return a.request({ data, url, method, params });
};

export const requests = ({
  method = "PUT",
  data = {},
  url = "",
  params = {},
}) => {
  return a.request({ data, url, method, params });
};

export const AInformation = ({
  method = "post",
  data = {},
  url = "",
  params = {},
}) => {
  return a.request({ data, url, method, params });
};