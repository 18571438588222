<script>
import { defineComponent, onMounted } from "vue";
import OverLordPC from "../components/OverLordPC.vue";
// import OverLordPhone from "../components/OverLordPhone.vue";

export default defineComponent({
  components: { OverLordPC },
  setup() {
    const isPhone = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    onMounted(() => {
      document.title = "2X x OVERLORD 콜라보 시작!！";
    });
    return { isPhone };
  },
});
</script>
<template>
  <!-- <OverLordPhone></OverLordPhone> -->
  <OverLordPC></OverLordPC>
</template>
<style lang="less" scoped></style>
